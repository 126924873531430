    const generateHeaderFooterField = (form_header_footer_fields, custom_usage, use_in_form_data = false) => {
        let result = form_header_footer_fields.reduce(function (r, a) {
            r[custom_usage] = r[custom_usage] || [];
            if(a.usage == custom_usage) {
                if(use_in_form_data) {
                    if(a.field && a.field.source_type == 'api') {
                        generateDropdownValueFromAPIField(a.field.source, a.field.api_method).then(response => { 
                            a.field.field_drop_down_values_data = response; 
                        })
                    }
                }
                r[custom_usage].push(a);
            }
            return r;
        }, Object.create(null));
        let final = Object.entries(result).reduce((newObj, [key,val]) => {
            newObj[key] = val.reduce((arr, a) => {
                arr[a.row] = arr[a.row] || [];
                arr[a.row].push(a);
                return arr;
            }, {})
            return newObj;
        },Object.create(null))

        
        for(let fin in final[custom_usage]) {
            final[custom_usage][fin] = final[custom_usage][fin].sort(function(a, b) {
                return a.column - b.column;
            });
        }
        return final;
    }
    const generateDropdownValueFromAPIField =(api, dynamic_method) =>  {
        var customInstance = axios.create();
        customInstance.defaults.headers.common = {};
        return new Promise((resolve, reject) => {
            customInstance( 
                            {method: dynamic_method.toLowerCase(), url: api},
                            {validateStatus: () => true}
                        )
            .then(res => {
                switch(res.status) {
                    case 200: 
                        resolve(res.data.data);
                        break;

                }
            }).catch((error) => {
                // console.log(error)
                if(error.message == "Network Error") {
                    Vue.swal({
                        icon: "error",
                        title: "Network Error!",
                        text: "Please call system administrator",
                    })
                }
                else if(error.message == "Request failed with status code 401") {
                    Vue.swal({
                        icon: "error",
                        title: "Unauthorized!",
                        html: error.response.data ? error.response.data.message : 'Invalid Token.',
                    })
                }
                else if(error.message == "Request failed with status code 405") {
                    Vue.swal({
                        icon: "error",
                        title: "API Access Forbidden!",
                        html: error.response.data ? error.response.data.message : 'Method not allowed.',
                    })
                }
                else if(error.message == "Request failed with status code 403") {
                    Vue.swal({
                        icon: "error",
                        title: "API Access Forbidden!",
                        html: 'Method not allowed.',
                    })
                }
                else {
                    Vue.swal({
                        icon: "error",
                        title: error.message,
                        text: 'Something went wrong, please call system administrator.',
                    })
                }
            })
        })
    }
    const generateHeaderFooterFieldValue =(array) =>  {
        let result = {};
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            if(element.field && element.form_header_footer_field_value) {
                let el = {
                    [element.id]: element.form_header_footer_field_value.value 
                }
                Object.assign(result, el)
            }
        }
        return result;
    }
    const generateDetailField =(form_detail_fields, use_in_form_data = false) =>  {
        let row = [];
        for (let index = 0; index < form_detail_fields.length; index++) {
            const element = form_detail_fields[index];
            if(use_in_form_data) {
                if(element.field && element.field.source_type == 'api') {
                    generateDropdownValueFromAPIField(element.field.source, element.field.api_method).then(response => { 
                        element.field.field_drop_down_values_data = response; 
                    })
                }
            }
            row.push(element);
        }
        return row;
    }
    const generateDetailFieldValue =(array) =>  {
        let result = {};
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            if(element.field) {
                let el;
                let parent_count = 1;

                for (let y = 0; y < element.form_detail_field_value.length; y++) {
                    const subElement = element.form_detail_field_value[y];
                    el = {
                        ['line_'+subElement.line_number+'#fieldid_'+subElement.fdf_id]: subElement.value 
                    }
                    parent_count++;
                    Object.assign(result, el)
                }
            }
        }
        return result;
    }
    const fixedHeaderFooterFieldsArray =(data) =>  {
        let header_footer_field = [];
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            let row;
            if(element.field) {
                row = {
                    id: element.id,
                    column: element.column,
                    display_name: element.field.display_name,
                    field_custom_name: element.field_custom_name,
                    field_id: element.field.id,
                    random_id: Math.floor(Math.random() * 100) + Math.floor(Math.random() * 100) + 1,
                    row: element.row,
                    source_type: element.field.source_type,
                    type: element.field.field_type.name,
                    usage: element.usage,
                    readonly: element.readonly == "1" ? true : false,
                    aggregate_field: false,
                    aggregate_function: null,
                    aggregate_column_field_id: null,
                    aggregate_column: null,
                    validation: element.validation,
                    hf_field_value_count: element.hf_field_value_count,
                    label_format: element.field.label_format,
                    label_value: element.field.label_value,
                }
            } else if(!element.field && element.aggregate_function) {
                row = {
                    id: element.id,
                    column: element.column,
                    display_name: "Aggregate Function",
                    field_custom_name: null,
                    field_id: null,
                    random_id: Math.floor(Math.random() * 100) + Math.floor(Math.random() * 100) + 1,
                    row: element.row,
                    source_type: "none",
                    type: "n/a",
                    usage: element.usage,
                    readonly: element.readonly == "1" ? true : false,
                    aggregate_field: element.aggregate_field == "1" ? true : false,
                    aggregate_function: element.aggregate_function,
                    aggregate_column_field_id: parseInt(element.aggregate_column_field_id),
                    aggregate_column: element.aggregate_column_field ? element.aggregate_column_field.parameter_name : null,
                    validation: null,
                    hf_field_value_count: element.hf_field_value_count,
                    label_format: null,
                    label_value: null,
                }
            } else {
                row = {
                    id: element.id,
                    column: element.column,
                    display_name:"Empty Block",
                    field_custom_name: null,
                    field_id: null,
                    random_id: Math.floor(Math.random() * 100) + Math.floor(Math.random() * 100) + 1,
                    row: element.row,
                    source_type: "none",
                    type: "n/a",
                    usage: element.usage,
                    validation: null,
                    readonly: false,
                    aggregate_field: element.aggregate_field == "1" ? true : false,
                    aggregate_column_field_id: null,
                    aggregate_column: null,
                    aggregate_function: null,
                    hf_field_value_count: 0,
                    label_format: null,
                    label_value: null,
                }
            }
            header_footer_field.push(row);
        }
        return header_footer_field;
    }
    const fixedDetailFieldsArray =(data) =>  {
        let detail_field = [];
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            let row;
            if(element.field) {
                row = {
                    id: element.id,
                    column: element.column,
                    display_name: element.field.display_name,
                    field_custom_name: element.field_custom_name,
                    parameter_name: element.field.parameter_name,
                    field_id: element.field.id,
                    source_type: element.field.source_type,
                    type: element.field.field_type.name,
                    validation: element.validation,
                    readonly: element.readonly == '1' ? true : false,
                    d_field_value_count: element.d_field_value_count,
                    label_format: element.field.label_format,
                    label_value: element.field.label_value,
                }
            } else {
                row = {
                    id: element.id,
                    column: element.column,
                    display_name:"Empty Block",
                    field_id: null,
                    field_custom_name: null,
                    source_type: "none",
                    type: "n/a",
                    validation: element.validation,
                    readonly: false,
                    d_field_value_count: element.d_field_value_count,
                    label_format: null,
                    label_value: null,
                }
            }
            detail_field.push(row);
        }
        return detail_field;
    }
    const filterObjectFromArray =(array, key, value) =>  {
        return array.filter(function (obj) {
            return obj[key] === value;
        });
    }
    const sortDetailFields =(detail_field) =>  {
        return detail_field.sort(function (x, y) {
            return x.column - y.column;
        });
    }
    const sortHeaderFooterFields =(header_footer_field) =>  {
        return header_footer_field.sort(function (x, y) {
            return parseInt(x.row) - parseInt(y.row) || parseInt(x.column) - parseInt(y.column);
        });
    }
    const generateDefaultValueToField = (fields) => {
        let defaultValue = {};
        for (let index = 0; index < fields.length; index++) { 
            const element = fields[index];
            if(element.field) {
                if(element.field.field_type.name == 'date' 
                    && (element.validation && element.validation.split('|')[0] == 'required'))  { // date field
                    Object.assign(defaultValue, { [element.id] : new Date()})
                }
    
                if(element.field.field_type.name == 'dropdown' 
                    && element.field.field_drop_down_values_data.length == 1 
                        && (element.validation && element.validation.split('|')[0] == 'required')) { // dropdown that has only one value
                    Object.assign(defaultValue, { [element.id] : element.field.field_drop_down_values_data[0].value})
                }
            }
        }
        return defaultValue;
    }
export default {
    generateHeaderFooterField,
    generateDropdownValueFromAPIField,
    generateHeaderFooterFieldValue,
    generateDetailField,
    generateDetailFieldValue,
    fixedHeaderFooterFieldsArray,
    fixedDetailFieldsArray,
    filterObjectFromArray,
    sortDetailFields,
    sortHeaderFooterFields,
    generateDefaultValueToField,
}